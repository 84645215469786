import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import {
  Container,
  Spacer,
  Column,
  Grid,
  CardProduct,
  Link,
  Pagination,
} from 'ascential-horizon'

import { useIdentity } from '../providers/identityContext'
import getThemeConfig from '../providers/config/themeConfig'
// import { ellipsify } from '../utils'
import { generateSlug } from '../utils/slugifier'
import { trackLinkClick } from '../services/tracking'
import {
  CategoryContainer,
  SelectField,
  ClearFilters,
} from '../components/Digest/Digest.style'
import Layout from '../Layout'

const TemplateDigestListingPage = ({ data, pageContext, location }) => {
  const { loggedIn, user } = useIdentity()
  const {
    footerNav,
    header,
    headerAuth,
    home: { footer },
  } = data

  const {
    footer: { type },
    header: { variation },
  } = getThemeConfig()

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const {
    allArticles,
    featuredArticles,
    category,
    limit,
    currentPage,
    skip,
  } = pageContext

  const layoutProps = {
    header: getHeaderProps(),
    footer: footerProps,
    pageContext,
    location,
  }

  const getAllCategories = () => {
    const articleCat = allArticles.map((cat) => {
      return cat.category
    })
    const uniqueCategories = [...new Set(articleCat)]
    return uniqueCategories
  }

  const getAllBrands = () => {
    const articleBrands = allArticles.map((brnd) => {
      return brnd.brand
    })
    const uniqueBrands = [...new Set(articleBrands)]
    return uniqueBrands
  }

  const allCategories = getAllCategories()
  const allBrands = getAllBrands()

  const [categoryValue, setCategoryValue] = useState('')
  const [brandValue, setBrandValue] = useState('')
  const [scrollValue, setScrollValue] = useState(false)

  const digestPath = '/digest'
  const pathLocation = location.pathname
  const isDigestPage =
    pathLocation === digestPath || pathLocation === `${digestPath}/`

  // Check if the page get refreshed. This will help with
  // the scrolling to filters from other pages
  const isDigestPageRefreshed = location.action !== 'PUSH'

  useEffect(() => {
    const categValue = window.localStorage.getItem('CATEGORY')
    setCategoryValue(categValue)
    const brndValue = window.localStorage.getItem('BRAND')
    setBrandValue(brndValue)
    const scrlValue = window.localStorage.getItem('SCROLL_TO_FILTERS')
    setScrollValue(scrlValue)
  }, [])

  useEffect(() => {
    window.localStorage.setItem('CATEGORY', categoryValue)
    window.localStorage.setItem('BRAND', brandValue)
    window.localStorage.setItem('SCROLL_TO_FILTERS', scrollValue)
    if (isDigestPage) {
      setScrollValue(false)
    } else {
      setScrollValue(true)
    }
  }, [categoryValue, brandValue, scrollValue])

  const scrollToFilters = useCallback((element) => {
    if (element)
      window.scrollTo({
        top: element
          ? element.current.getBoundingClientRect().top + window.scrollY - 100
          : 0,
        behavior: element ? 'smooth' : 'auto',
      })
  }, [])

  const anchorFilters = useRef()

  useEffect(() => {
    if (anchorFilters.current && !isDigestPageRefreshed) {
      scrollToFilters(anchorFilters)
    }
  }, [])

  // Get articles when Selecting the category
  const categArticles = allArticles.filter((article) => {
    return generateSlug(article.category) === categoryValue
  })

  // Get articles when Selecting the brand
  const brndArticles = allArticles.filter((article) => {
    return generateSlug(article.brand) === brandValue
  })

  // Get articles when Selecting brand and category
  const multipleSelectedArticles = allArticles.filter((article) => {
    return (
      generateSlug(article.brand) === brandValue &&
      generateSlug(article.category) === categoryValue
    )
  })

  const parseCategories = (cats) => {
    return cats.map((cat) => ({
      label: cat,
      value: generateSlug(cat),
    }))
  }

  const isAllCategories =
    categoryValue === '' || categoryValue === null || categoryValue === 'null'
  const isAllBrands =
    brandValue === '' || brandValue === null || brandValue === 'null'
  const isAllSelectDefault = isAllCategories && isAllBrands

  let articlesPerState = []

  if (categoryValue && !brandValue) {
    articlesPerState = categArticles
  } else if (brandValue && !categoryValue) {
    articlesPerState = brndArticles
  } else if (brandValue && categoryValue) {
    articlesPerState = multipleSelectedArticles
  } else if (isAllSelectDefault) {
    articlesPerState = allArticles
  }

  const onCategoryChange = (event) => {
    setCategoryValue(event)
    if (!isDigestPage) {
      navigate(`${digestPath}`)
    }
  }

  const onBrandChange = (event) => {
    setBrandValue(event)
    if (!isDigestPage) {
      navigate(`${digestPath}`)
    }
  }

  const onClearFilters = () => {
    setCategoryValue('')
    setBrandValue('')
    if (isDigestPage && !isDigestPageRefreshed) {
      scrollToFilters()
    } else {
      navigate(`${digestPath}`)
    }
  }

  // Placeholder option field for Categories
  const categoryPlaceholder = {
    disabled: false,
    hidden: false,
    intro: 'Select a category',
    placeholderValue: '',
  }

  // Pagination on page change
  const onPageChange = (e, page) => {
    e.preventDefault()
    const pagePath = page > 1 ? page : ''

    if (categoryValue && !brandValue) {
      const categoryPath = generateSlug(categoryValue)
      navigate(`${digestPath}/${categoryPath}/${pagePath}`)
    } else if (!categoryValue && brandValue) {
      const brandPath = generateSlug(brandValue)
      navigate(`${digestPath}/${brandPath}/${pagePath}`)
    } else {
      navigate(`${digestPath}/${pagePath}`)
    }
  }
  // Pagination next page
  const onNext = (e) => {
    onPageChange(e, currentPage + 1)
  }
  // Pagination previous page
  const onPrevious = (e) => {
    onPageChange(e, currentPage - 1)
  }

  // Placeholder option field for Brands
  const brandPlaceholder = {
    disabled: false,
    hidden: false,
    intro: 'Select a brand',
    placeholderValue: '',
  }

  // This is the initial value of the Select
  const selectInitialValue = {
    label: '',
    value: '',
  }

  const artsPerPage = articlesPerState.slice(skip, limit * currentPage)

  const renderCatArticles = (
    index = artsPerPage.length,
    columnWidth = [12, 6, 4, 4]
  ) =>
    artsPerPage?.slice(0, index).map((article) => {
      const articleProps = {
        ...article,
        image: (article.image && article.image.fluid.src) || null,
        label: article.brand,
        tag: article.category?.toUpperCase(),
        secondaryCta: {
          label: 'Read more',
          url: `/digest/${article.slug}`,
        },
        // copy: ellipsify(
        //   article.content.find((item) => item.copy).copy.copy,
        //   110
        // ),
      }

      return (
        <Column
          key={article.id}
          width={columnWidth}
          px={[0, 'small', 'medium']}>
          <Link
            onClick={() => trackLinkClick(article.title, article.slug, 'card')}
            to={`/digest/${article.slug}`}>
            <CardProduct
              {...articleProps}
              image={{ src: articleProps.image }}
            />
          </Link>
        </Column>
      )
    })

  const renderFeaturedArticles = () =>
    // We only want max the first 2 articles
    featuredArticles?.slice(0, 2).map((article) => {
      const featuredArticlesProps = {
        ...article,
        secondaryCta: {
          label: 'Read more',
          url: `/digest/${article.slug}`,
        },
        tag: article.category?.toUpperCase(),
        label: article.brand,
        // copy: ellipsify(
        //   article.content.find((item) => item.copy).copy.copy,
        //   110
        // ),
        image: (article.image && article.image.fluid.src) || null,
      }

      return (
        <Column
          mb={[0, 'xsmall', 'xsmall']}
          key={article.id}
          width={[12, 6, 6, 6]}
          px={[0, 'small', 'medium']}>
          <Link
            onClick={() => trackLinkClick(article.title, article.slug, 'card')}
            to={`/digest/${article.slug}`}>
            <CardProduct
              {...featuredArticlesProps}
              image={{ src: featuredArticlesProps.image }}
            />
          </Link>
        </Column>
      )
    })

  return (
    <Layout {...layoutProps}>
      <Spacer space={`${category ? 'small' : 'xxlarge'}`}>
        <Container>
          {/* show featured article only on the first page */}
          {isDigestPage && (
            <Grid>
              {renderFeaturedArticles()}
              {/* In case there is only one featured article, show the first
            article from the articles' list, so that way we'll always show
            two articles in the featured section */}
              {featuredArticles.length === 1 &&
                renderCatArticles(1, [12, 6, 6, 6])}
            </Grid>
          )}

          <CategoryContainer>
            <div ref={anchorFilters}>
              <SelectField
                placeholder={brandPlaceholder}
                label="Brand:"
                name="brands"
                value={brandValue}
                element={selectInitialValue}
                items={parseCategories(allBrands)}
                onChangeCallback={(value) => onBrandChange(value)}
              />
              <SelectField
                placeholder={categoryPlaceholder}
                label="Category:"
                name="categories"
                value={categoryValue}
                element={selectInitialValue}
                items={parseCategories(allCategories)}
                onChangeCallback={(value) => onCategoryChange(value)}
              />
            </div>
            <ClearFilters onClick={onClearFilters}>Clear Filters</ClearFilters>
          </CategoryContainer>

          <Grid>{renderCatArticles()}</Grid>

          <Pagination
            data={articlesPerState}
            itemsPerPage={limit}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        </Container>
      </Spacer>
    </Layout>
  )
}

TemplateDigestListingPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateDigestPageQuery = graphql`
  query($rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      footer {
        ...footerQuery
      }
    }
  }
`

export default TemplateDigestListingPage
